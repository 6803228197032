import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

class Progetti extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      searchResult: [],
      loading: false,
      reparto: ""
    }
  }

  search = (e) => {
    e.preventDefault();
  }

  handleChange(event) {
    console.log("set",event.target.value);
    this.setState({reparto: event.target.value});
  }
 
  render() { 

    const { loading, reparto } = this.state;

    const alldata = this.props.data.allContentfulProgetto.edges.map(item => item.node);
   
    const result = alldata.filter((item) => {
      return (!reparto || (item.reparti && item.reparti.includes(reparto)))
    })

    const breadcrumb = [
      {"title":"Progetti"}
    ];
    
    
    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Progetti"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-12">
              <h1>Progetti</h1>
              <p>Progetti realizzati dal centro Zootecnico didattico sperimentale</p>
            </div>
          </div>
        </div>
        <div className="section section--dark section--m-bottom" role="region" aria-labelledby="region3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <form action="#" onSubmit={this.search} method="post" name="ricerca-reparto" className="form">
                  <fieldset className="form__fieldset">
                    <legend className="form__legend visuallyhidden">Scegli il reparto di tuo interesse</legend>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="form__group">
                          <label htmlFor="role" className="form__label">Scegli il reparto di tuo interesse</label>
                          <div className="form__group--select">
                            <select  value={this.state.ruolo}  onChange={(e) => this.handleChange(e)} name="reparto" id="role">
                              <option value="">Tutti</option>
                              <option value="Bovini">Bovini</option>
                              <option value="Suini">Suini</option>
                              <option value="Zoocolture">Zoocolture</option>
                              <option value="Acquacoltura">Acquacoltura</option>
                              <option value="Apicoltura/Apiario">Apicoltura/Apiario</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container" ref="resultsContainer">
          {!loading &&
          <div className="row" id="elenco">
            <div className="col-12">
              <p>
                <strong>Sono stati trovati {result.length} risultati</strong>
              </p>
            </div>
          </div>
          }
          <div className="row">
            {loading &&
              <div className="col-sm-12 text-center loader">
                <img alt="loading" src="/images/loader.gif"/>
              </div>
            } 
            {result.map((sresult,index) => {
              return (
                <div key={index} className="col-12">
                  <div className="progetti-list">
                    <h3>
                      <Link to={'/progetti/'+sresult.friendlyUrl} title={"Vai alla scheda di " + sresult.cognome} className="progetti-list__name">{sresult.titolo}</Link>
                    </h3>
                    {!!sresult.durataDelProgetto &&
                      <p className="progetti-list__durata"><strong>Durata del progetto: </strong> {sresult.durataDelProgetto}</p>
                    }
                  </div>
                </div>
              )
            })}
          </div>
       
        </div>
      </Layout>  
    )
  }

}

export default Progetti;

export const query = graphql`
query {
  allContentfulProgetto(filter: {node_locale: {eq: "it-IT"} }) {
    edges {
      node {
        titolo
        reparti
        friendlyUrl
        durataDelProgetto
      }
    }
  }
}
`